import React, { Fragment, useCallback, useRef } from "react";
import { graphql, Link, PageProps } from "gatsby";
import { PortableText } from "@portabletext/react";
import type { PortableTextBlock } from "@portabletext/types";
import styled from "styled-components";
import { Box, Heading, Grid } from "grommet";

import Seo from "@components/Seo";
import Text from "@components/Text";
import Paragraph from "@components/Paragraph";
import Button from "@components/Button";
import Section from "@components/Section";
import PageHero from "@components/PageHero";
import SanityImage from "@components/SanityImage";
import HubSpotSectionContent from "@components/HubSpotSectionContent";
import CustomerStoriesCarousel from "@components/CustomerStoriesCarousel";
import SolutionsCarousel from "@components/SolutionsCarousel";
import ResponsiveContainerBox from "@components/ResponsiveContainerBox";
import { defaultComponents, tickBulletList } from "@utils/PortableText";
import { getMobileBreakpoint, getDesktopBreakpoint } from "@utils/Grommet";

const HeroContent = styled(Box)`
  padding-right: 150px;

  @media (max-width: ${getDesktopBreakpoint}px) {
    padding-right: 100px;
  }

  @media (max-width: ${getMobileBreakpoint}px) {
    padding-right: 0px;
    /* max-width: 350px; */
  }
`;

const ConstrainedBox = styled(Box)`
  max-width: 565px;

  @media (max-width: ${getDesktopBreakpoint}px) {
    max-width: 450px;
  }
`;

const ApproachGrid = styled(Grid)`
  width: 100%;
  max-width: 930px;

  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 90px;
  grid-auto-flow: row dense;

  & > *:nth-child(4n -3) {
    grid-column-start: 2;
  }

  @media (max-width: ${getDesktopBreakpoint}px) {
    max-width: 630px;
    grid-column-gap: 33px;
    grid-row-gap: 50px;
  }

  @media (max-width: ${getMobileBreakpoint}px) {
    grid-template-columns: 1fr;
    grid-auto-flow: unset;

    & > *:nth-child(4n -3) {
      grid-column-start: unset;
    }
    max-width: 300px;
  }
`;

const ApproachLink = styled(Link)`
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }

  @media (max-width: ${getMobileBreakpoint}px) {
    display: none;
  }
`;

const BenefitsGrid = styled(Grid)`
  grid-template-columns: 1fr 1fr;
  grid-gap: 28px;

  @media (max-width: ${getDesktopBreakpoint}px) {
    & img {
      transform: scale(0.7);
      margin-left: -5px;
      margin-bottom: -5px;
    }
  }

  @media (max-width: ${getMobileBreakpoint}px) {
    grid-template-columns: 1fr;
    grid-gap: 15px;

    max-width: 280px;
  }
`;

type TSolutionQuery = Queries.SolutionQuery & {
  solution: {
    _rawDescription?: PortableTextBlock[];
    _rawBenefitsBody?: PortableTextBlock[];
  };
};

const Solution = ({ data, location }: PageProps<TSolutionQuery>) => {
  const hsRef = useRef<HTMLDivElement>(null);

  const handleHsScroll = useCallback(() => {
    hsRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }, [hsRef?.current]);

  const solution = data?.solution;
  const otherSolutions = data?.otherSolutions?.edges?.map((edge) => edge?.node);
  const site = data?.site;

  const ogImage = solution?.cardImage?.image?.asset?.url;

  return (
    <Box>
      <Seo
        title={solution?.title ?? ""}
        description={solution?.metaDescription ?? undefined}
        ogImage={ogImage}
        ogUrl={location?.href}
      />

      <PageHero seoImage={solution?.heroImage}>
        <HeroContent align="start" margin={{ bottom: "small" }}>
          <Text weight={500} margin={{ bottom: "xxsmall" }}>
            Learning Solutions
          </Text>
          <Heading
            level={1}
            size="large"
            color="grey-10"
            margin={{ bottom: "xsmall" }}
          >
            {solution?.title}
          </Heading>
          <Paragraph size="large" margin={{ bottom: "small" }}>
            {solution?.intro}
          </Paragraph>

          <Button
            label="Get in touch"
            background="grey-10"
            color="grey-3"
            size="large"
            onClick={handleHsScroll}
          />
        </HeroContent>
      </PageHero>

      <Section constrained="hd" background="grey-3">
        <Box pad={{ vertical: "xlarge" }} align="center">
          <ConstrainedBox fill="horizontal">
            <PortableText
              value={solution?._rawDescription}
              components={defaultComponents({ hLevel: 2 })}
            />
          </ConstrainedBox>
        </Box>
      </Section>

      <Section pad={{ vertical: "xlarge" }}>
        <ResponsiveContainerBox
          constrained="hd"
          padded="thick"
          margin={{ bottom: "large" }}
        >
          <Box margin={{ bottom: "xxsmall" }} direction="row" justify="between">
            <Text weight={500}>Our Approach</Text>
            <ApproachLink to="/features">
              <Text>View product features</Text>
            </ApproachLink>
          </Box>
          <Heading level={2} margin={{ bottom: "xlarge" }}>
            {solution?.approachTitle}
          </Heading>

          <Box align="center" fill>
            <ApproachGrid>
              {solution?.approachBody?.map((item, index) => (
                <Fragment key={index}>
                  <Box>
                    <SanityImage
                      {...item?.image?.image}
                      alt={item?.image?.alt}
                    />
                  </Box>
                  <Box>
                    <PortableText
                      value={item?._rawText as any}
                      components={tickBulletList({
                        hLevel: 3,
                        hMargin: "xxsmall",
                      })}
                    />
                  </Box>
                </Fragment>
              ))}
            </ApproachGrid>
          </Box>
        </ResponsiveContainerBox>
      </Section>

      <Section constrained="hd" background="grey-10">
        <Box pad={{ vertical: "xlarge" }} align="center">
          <ConstrainedBox fill="horizontal">
            <Text margin={{ bottom: "xxsmall" }} weight={500}>
              The Benefits
            </Text>
            <Heading level={2} margin={{ bottom: "medium" }}>
              {solution?.benefitsTitle}
            </Heading>

            <Box align="center" margin={{ bottom: "small" }}>
              <BenefitsGrid>
                {solution?.benefits?.map((benefit, index) => (
                  <Box key={index} gap="xxsmall">
                    <Box height="34px" justify="end">
                      <SanityImage
                        {...benefit?.icon?.image}
                        alt={benefit?.icon?.alt}
                        imgwidth={`${benefit?.icon?.image?.asset?.width}px`}
                        imgheight={`${benefit?.icon?.image?.asset?.height}px`}
                        noProportionalPlaceholder
                      />
                    </Box>
                    <PortableText
                      value={benefit?._rawDescription as any}
                      components={defaultComponents()}
                    />
                  </Box>
                ))}
              </BenefitsGrid>
            </Box>

            <PortableText
              value={solution?._rawBenefitsBody}
              components={defaultComponents()}
            />
          </ConstrainedBox>
        </Box>
      </Section>

      <Section pad={{ top: "xlarge" }}>
        <ResponsiveContainerBox
          constrained="hd"
          padded="thick"
          margin={{ bottom: "large" }}
        >
          <Heading level={2}>Related Customer Stories</Heading>
        </ResponsiveContainerBox>

        <CustomerStoriesCarousel customerStories={solution?.customerStories} />
      </Section>

      <Section pad={{ top: "large", bottom: "xlarge" }} background="brand">
        <ResponsiveContainerBox
          constrained="hd"
          padded="thick"
          margin={{ bottom: "large" }}
        >
          <Heading level={2} color="grey-10">
            More Learning Solutions
          </Heading>
        </ResponsiveContainerBox>

        <SolutionsCarousel solutions={otherSolutions} />
      </Section>

      <Section ref={hsRef} constrained="hd" background="grey-3">
        <HubSpotSectionContent
          hsPortalId={site?.hsPortalId}
          hsFormId={site?.hsFormId}
        />
      </Section>
    </Box>
  );
};

export const query = graphql`
  query Solution($slug: String!) {
    solution: sanitySolution(slug: { current: { eq: $slug } }) {
      _id
      id

      title
      metaDescription
      cardImage {
        ...SeoOgImage
      }

      intro
      heroImage {
        ...PageHero
      }

      slug {
        current
      }

      _rawDescription

      approachTitle
      approachBody {
        image {
          image {
            ...ImageWithPreview
          }
          alt
        }
        _rawText
      }

      benefitsTitle
      benefits {
        icon {
          image {
            ...ImageWithPreview
            asset {
              height
              width
            }
          }
          alt
        }
        _rawDescription
      }
      _rawBenefitsBody

      customerStories {
        ...CustomerStoriesCarousel
      }
    }

    otherSolutions: allSanitySolution(
      filter: { slug: { current: { ne: $slug } } }
      sort: { fields: order }
    ) {
      edges {
        node {
          ...SolutionsCarousel
        }
      }
    }

    site: sanitySite {
      _id
      id

      ...HubSpotForm
    }
  }
`;

export default Solution;
