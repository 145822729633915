import React, { useContext } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Box, ResponsiveContext, ThemeContext, ThemeType } from "grommet";
import { Swiper as BaseSwiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

import SolutionCard, {
  cardWidthHD,
  cardWidthDesktop,
  cardHeightHD,
  cardHeightDesktop,
  cardPadHD,
  cardPadDesktop,
} from "@components/SolutionCard";
import { getDesktopBreakpoint, getMobileBreakpoint } from "@utils/Grommet";

import "swiper/css";
import "swiper/css/navigation";

const Swiper = styled(BaseSwiper)`
  width: 100%;
  max-width: ${cardWidthHD * 2 + cardPadHD * 4}px;
  height: ${cardHeightHD}px;

  overflow: visible;

  @media (max-width: ${getDesktopBreakpoint}px) {
    max-width: ${cardWidthDesktop * 2 + cardPadDesktop * 4}px;
    height: ${cardHeightDesktop}px;
  }

  @media (max-width: ${getMobileBreakpoint}px) {
    max-width: ${cardWidthDesktop + cardPadDesktop * 2}px;
  }

  & .swiper-slide {
    opacity: 0.5;
    transition: opacity 300ms ease-in-out;

    padding: 0 ${cardPadHD}px;
    max-width: ${cardWidthHD}px;

    @media (max-width: ${getDesktopBreakpoint}px) {
      max-width: ${cardWidthDesktop}px;
      padding: 0 ${cardPadDesktop}px;
    }
  }

  & .swiper-slide-visible {
    opacity: 1;
  }

  & .swiper-button-prev,
  & .swiper-button-next {
    color: #4a4a4a;
    border: 1px solid #e0e0e0;
    background: #ffffff;
    border-radius: 50%;
    width: 40px;
    height: 40px;

    &:after {
      font-size: 18px;
      font-weight: bold;
    }

    @media (max-width: ${getMobileBreakpoint}px) {
      width: 30px;
      height: 30px;

      &:after {
        font-size: 15px;
      }
    }
  }

  & .swiper-button-prev {
    left: -19%;

    @media (max-width: ${getMobileBreakpoint}px) {
      left: -10px;
    }
  }

  & .swiper-button-next {
    right: -19%;

    @media (max-width: ${getMobileBreakpoint}px) {
      right: -10px;
    }
  }
`;

type TProps = {
  solutions?: ReadonlyArray<
    Queries.SolutionsCarouselFragment | undefined | null
  > | null;
};

const SolutionsCarousel = ({ solutions }: TProps) => {
  const theme = useContext<ThemeType>(ThemeContext);
  const size = useContext<string>(ResponsiveContext);

  return (
    <Box fill="horizontal" overflow="hidden" align="center">
      {size === "small" ? (
        <Box width={{ max: `${cardWidthDesktop}px` }} gap="medium">
          {solutions?.map((solution, index) => (
            <SolutionCard solution={solution} key={index} />
          ))}
        </Box>
      ) : (
        <Swiper
          modules={[Navigation]}
          navigation={true}
          grabCursor
          speed={500}
          watchSlidesProgress
          slidesPerView={1}
          breakpoints={{
            [theme?.global?.breakpoints?.small?.value ?? 0]: {
              slidesPerView: "auto",
            },
          }}
        >
          {solutions?.map((solution, index) => (
            <SwiperSlide key={index}>
              <SolutionCard solution={solution} fill="vertical" />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </Box>
  );
};

export default SolutionsCarousel;

export const query = graphql`
  fragment SolutionsCarousel on SanitySolution {
    ...SolutionCard
  }
`;
